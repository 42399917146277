import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { logoGif } from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "amazonbookpublishingprofessionals.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=594853';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://amazonbookpublishingprofessionals.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="">
      <Modal open={open} onClose={onCloseModal} center>
        <div className="balti_popup">
          <h2>We are here to help!</h2>
          <p>Sign up Now To Avail 50% Discount!</p>
          <form method="POST" class="form_submission" onSubmit={onSubmitFunc}>
            <div className="row">
              <div class="col-md-12">
                <ul>
                  <li>
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <input
                      type="text"
                      class="required"
                      placeholder="Full Name *"
                      name="name"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </li>
                </ul>
              </div>
              <div class="col-md-6 pd-right-0">
                <ul>
                  <li>
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <input
                      type="email"
                      class="required email"
                      placeholder="Email Address *"
                      name="email"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul>
                  <li>
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <input
                      type="number"
                      class="required number"
                      minlength="10"
                      maxlength="12"
                      placeholder="Phone No. *"
                      name="phone"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                    />
                    {errors && (
                      <div className="error">
                        Phone number must be at least 9 digits long
                      </div>
                    )}
                  </li>
                </ul>
              </div>
              <div class="col-md-12">
                <ul>
                  <li>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    <textarea
                      name="message"
                      class="required"
                      required="required"
                      placeholder="To help us understand better enter a brief description of your project."
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          message: e.target.value,
                        })
                      }
                    ></textarea>
                  </li>
                </ul>
              </div>
              <div class="col-md-12">
                <ul>
                  <li>
                    {loading == true ? (
                      <div class="loader">
                        <img alt="loader" src={logoGif} />
                      </div>
                    ) : (
                      <input type="submit" name="submit" value="Submit" />
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
